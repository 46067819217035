import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import Button from "../Button/Button"
import SectionHeading from "../SectionHeading/SectionHeading"
import { AirlineFlight, DestinationsFlightsProps } from "./Destinations.d"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"

const DestinationsFlights: React.FC<DestinationsFlightsProps> = ({
  nonstopAirlineSection,
}) => {
  const sectionHeadline = nonstopAirlineSection?.headline
  const [text, setText] = useState({ sectionHeadline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ sectionHeadline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([sectionHeadline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const airlineWidthPercent = `${
    100 / nonstopAirlineSection?.airlineFlights?.length
  }%`
  const largestDirectFLightCount = nonstopAirlineSection?.airlineFlights
    ?.map((airline: AirlineFlight) => {
      return airline?.nonstopFlights?.length
    })
    .sort((a: number, b: number) => a - b)
    .reverse()[0]

  const getRowValue = (rowNumber: number) => {
    return nonstopAirlineSection?.airlineFlights?.map(
      (airline: AirlineFlight, i: number) => {
        let cityName
        console.log(airline?.nonstopFlights?.length > rowNumber)

        if (airline?.nonstopFlights?.length > rowNumber) {
          cityName = airline.nonstopFlights[rowNumber].airportInitial
        } else {
          cityName = ""
        }
        return (
          <td css={[tw`py-1.5`, { width: airlineWidthPercent }]} key={i}>
            <div css={[tw`flex mx-10`]}>
              <p css={[tw`w-14`]}>
                {airline?.nonstopFlights?.length > rowNumber
                  ? airline.nonstopFlights[rowNumber].airportName
                  : ""}
              </p>
              <p css={[tw`2xl:whitespace-nowrap`]}>{cityName}</p>
            </div>
          </td>
        )
      }
    )
  }

  const getTableRows = () => {
    let rows = []
    for (let i = 0; i < largestDirectFLightCount; i++) {
      const idEvenRow = i % 2 === 0
      rows.push(
        <tr css={[idEvenRow ? tw`bg-lightGray` : tw`bg-altTableGray`]}>
          {getRowValue(i)}
        </tr>
      )
    }
    return rows
  }

  return (
    <div css={[tw`xl:block hidden`]}>
      <SectionHeading
        seasonalText="** Seasonal route"
        textStyles={tw`text-2xl`}
        css={tw`mt-20`}
      >
        {text?.sectionHeadline}
      </SectionHeading>
      <div css={[tw`flex flex-wrap justify-around mt-16 mx-auto gap-[1%]`]}>
        {nonstopAirlineSection &&
          nonstopAirlineSection?.airlineFlights?.map(
            ({ airlineLogo, airlineLink }: AirlineFlight) => (
              // eslint-disable-next-line react/jsx-key
              <div
                css={[
                  tw`flex flex-col items-center w-[19%!important]`,
                  { width: airlineWidthPercent },
                ]}
              >
                <div css={[tw`w-full first:ml-0 last:mr-0 mb-0`]}>
                  <Image
                    src={
                      airlineLogo?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    publicURL={airlineLogo?.localFile?.publicURL}
                    alt=""
                    aria-hidden
                    css={[tw`w-full mx-auto w-40 2xl:w-60 h-auto`]}
                  />
                  <Button
                    ariaLabel={airlineLink?.title}
                    styles={tw`w-full mt-8 mb-10`}
                    wrapperStyles={tw`w-full flex justify-center`}
                    link={airlineLink?.url}
                    text={airlineLink.title}
                  />
                </div>
              </div>
            )
          )}
      </div>
      <table css={[tw`mx-auto w-full`]}>
        <tbody>{getTableRows()}</tbody>
      </table>
    </div>
  )
}

export default DestinationsFlights
