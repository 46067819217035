import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Swiper as ISwiper } from "swiper"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import DestinationsLeftArrow from "../Icons/DestinationsLeftArrow"
import DestinationsRightArrow from "../Icons/DestinationsRightArrow"
import { DestinationsCarouselProps } from "./Destinations.d"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { Image } from "../Image"

const DestinationsCarousel: React.FC<DestinationsCarouselProps> = ({
  destinationsSection,
}) => {
  const sectionHeading = destinationsSection?.headline
  const [text, setText] = useState({ sectionHeading })
  const [cardTitle, setCardTitle] = useState("")
  const { language } = useContext(LanguageContext)
  const [swiper, setSwiper] = useState<ISwiper>()

  const slideLeft = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  const slideRight = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  useEffect(() => {
    if (language === "en") {
      setText({ sectionHeading })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([sectionHeading])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`mx-auto max-w-9xl pt-20`]}>
      <div css={[tw`relative`]}>
        <SectionHeading textStyles={tw`mb-8 `}>
          {text?.sectionHeading}
        </SectionHeading>
        <div css={[tw`lg:flex hidden absolute top-3 right-5 space-x-12 mr-4`]}>
          <DestinationsLeftArrow slideLeft={slideLeft} />
          <DestinationsRightArrow slideRight={slideRight} />
        </div>
      </div>
      <Swiper
        className="destination-carousel"
        slidesPerView={1.5}
        spaceBetween={18}
        navigation={{
          hiddenClass: ".swiper-button-hidden",
        }}
        loop={true}
        modules={[Navigation]}
        breakpoints={{
          1105: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        onSwiper={swiper => setSwiper(swiper)}
        css={[tw`lg:ml-4 lg:mr-4 mr-0 ml-10 overflow-hidden`]}
      >
        {destinationsSection &&
          destinationsSection?.destinationCards?.map(
            ({ backgroundImage, link, title }, i) => (
              <SwiperSlide key={i}>
                <div css={[tw`relative xxs:h-72 h-44 w-full overflow-hidden`]}>
                  <div
                    css={[
                      tw`bg-black opacity-30 h-full w-full z-1 absolute top-0 left-0`,
                    ]}
                  />
                  <Image
                    src={backgroundImage?.localFile?.childImageSharp?.gatsbyImageData}
                    publicURL={backgroundImage?.localFile?.publicURL}
                    alt=""
                    aria-hidden
                    css={[
                      tw`absolute left-0 top-0 h-full w-full -z-1 object-contain`,
                    ]}
                  />
                  <div
                    css={[tw`flex flex-col justify-center items-center h-full`]}
                  >
                    <h3
                      css={[
                        tw`text-center sm:text-3xl text-2xl text-white font-extrabold z-2`,
                      ]}
                      style={{ textShadow: "0 2px 8px rgba(0, 0, 0, 0.5)" }}
                    >
                      {title}
                    </h3>
                    <Button
                      ariaLabel={link?.title}
                      styles={tw`min-w-min mt-6 mx-3 z-1 sm:min-w-60 sm:mt-8`}
                      link={link?.url}
                      text={link?.title}
                    />
                  </div>
                </div>
              </SwiperSlide>
            )
          )}
      </Swiper>
    </div>
  )
}

export default DestinationsCarousel
