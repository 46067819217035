import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import tw from "twin.macro"
import Button from "../Button/Button"
import { Image } from "../Image"
import SectionHeading from "../SectionHeading/SectionHeading"
import { DestinationsFlightsMobileProps } from "./Destinations.d"

const DestinationsFlightsMobile: React.FC<DestinationsFlightsMobileProps> = ({
  nonstopAirlineSection,
}) => {
  return (
    <div css={[tw`block mt-20 xl:hidden`]}>
      <SectionHeading
        seasonalText="** Seasonal route"
        textStyles={tw`text-2xl`}
      >
        {nonstopAirlineSection?.headline}
      </SectionHeading>
      <div
        css={[
          tw`flex flex-wrap justify-center mt-16 mx-auto`,
          { maxWidth: "1475px" },
        ]}
      >
        {nonstopAirlineSection &&
          nonstopAirlineSection?.airlineFlights?.map(
            ({ airlineLogo, airlineLink, nonstopFlights }, i) => (
              <div
                css={[tw`flex flex-col items-center mb-18 last:mb-5`]}
                key={i}
              >
                <div css={[tw`xl:mb-0 mx-16`]}>
                  <Image
                    src={airlineLogo?.localFile?.childImageSharp?.gatsbyImageData}
                    publicURL={airlineLogo?.localFile?.publicURL}
                    alt=""
                    aria-hidden
                    css={[tw`w-60 h-9 mb-4`]}
                  />
                  <Button
                    ariaLabel={airlineLink?.title}
                    styles={tw`mb-10`}
                    wrapperStyles={tw`w-full flex justify-center`}
                    link={airlineLink?.url}
                    text={airlineLink?.title}
                  />
                </div>
                <div css={[tw`px-8 w-full`]}>
                  {nonstopFlights?.map(
                    ({ airportName, airportInitial }, index) => (
                      <div
                        css={[
                          tw`flex px-10 py-1`,
                          index % 2 === 0
                            ? tw`bg-lightGray`
                            : tw`bg-altTableGray`,
                        ]}
                        key={index}
                      >
                        <p css={[tw`w-14`]}>{airportName}</p>
                        <p>{airportInitial}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            )
          )}
      </div>
    </div>
  )
}

export default DestinationsFlightsMobile
