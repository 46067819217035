import React from "react"
import tw from "twin.macro"

interface IDestinationsRightArrow {
  slideRight: () => void
}

const DestinationsRightArrow: React.FC<IDestinationsRightArrow> = ({
  slideRight,
}) => {
  return (
    <svg
      css={[tw`cursor-pointer`]}
      onClick={slideRight}
      width="16"
      height="24"
      viewBox="0 0 16 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m1 2 12 10L1 22"
        stroke="#385885"
        stroke-width="3"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default DestinationsRightArrow
