import React from "react"
import tw from "twin.macro"

interface IDestinationsLeftArrow {
  slideLeft: () => void
}

const DestinationsLeftArrow: React.FC<IDestinationsLeftArrow> = ({
  slideLeft,
}) => {
  return (
    <svg
      css={[tw`cursor-pointer`]}
      onClick={slideLeft}
      width="16"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2 3 12l12 10"
        stroke="#385885"
        stroke-width="3"
        fill="none"
      />
    </svg>
  )
}

export default DestinationsLeftArrow
