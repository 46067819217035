import React from "react"
import { graphql, PageProps } from "gatsby"
import { AirlinesAndDestinationsPageData, TemplateProps } from "../templates"
import Destinations from "../../components/Destinations/Destinations"
import ImageHero from "../../components/Hero/ImageHero"
import { SEO } from "../../components/SEO"

const DestinationsPage: React.FC<PageProps> = ({
  data,
  location,
}: TemplateProps<AirlinesAndDestinationsPageData>) => {
  const { title, content, template, featuredImage } = data.wpPage

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <ImageHero image={featuredImage || null} />
      <Destinations
        title={title || ""}
        content={content || ""}
        template={template || null}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      template {
        ... on WpTemplate_AirlinesAndDestinations {
          templateName
          flightDestinationsPage {
            nonstopAirlineSection {
              headline
              airlineFlights {
                nonstopFlights {
                  airportName
                  airportInitial
                }
                airlineLink {
                  title
                  url
                }
                airlineLogo {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            destinationsSection {
              headline
              destinationCards {
                title
                link {
                  title
                  url
                }
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            mapSection {
              headline
              flightsMapImage {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`

export default DestinationsPage
