import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageHeading from "../PageHeading/PageHeading"
import SectionHeading from "../SectionHeading/SectionHeading"
import DestinationsFlights from "./DestinationsFlights"
import DestinationsCarousel from "./DestinationsCarousel"
import DestinationsFlightsMobile from "./DestinationsFlightsMobile"
import { DestinationsProps } from "./Destinations.d"
import { LanguageContext } from "../../context/Language"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { BackgroundContainer } from "../BackgroundContainer"
import { Image } from "../Image"

const Destinations: React.FC<DestinationsProps> = ({
  title,
  content,
  template,
}) => {
  const mapSection = template?.flightDestinationsPage?.mapSection
  const mapSectionHeading = mapSection?.headline
  const imageSrc = mapSection?.flightsMapImage?.localFile?.publicURL

  const [text, setText] = useState({ title, content, mapSectionHeading })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content, mapSectionHeading })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          title,
          content,
          mapSectionHeading,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section css={[tw`overflow-hidden pb-8 md:pb-16`]}>
      <BackgroundContainer>
        <PageHeading heading={title} content={content} />
        <SectionHeading css={tw`mt-20`}>
          {text?.mapSectionHeading}
        </SectionHeading>
      </BackgroundContainer>
      <Image
        src={imageSrc}
        alt=""
        aria-hidden
        css={[tw`px-4 mx-auto mt-6 sm:px-12 md:mt-16 w-full max-w-[1300px]`]}
      />
      {template && (
        <>
          <DestinationsFlights
            nonstopAirlineSection={
              template?.flightDestinationsPage?.nonstopAirlineSection
            }
          />
          <DestinationsFlightsMobile
            nonstopAirlineSection={
              template?.flightDestinationsPage?.nonstopAirlineSection
            }
          />
          <DestinationsCarousel
            destinationsSection={
              template?.flightDestinationsPage?.destinationsSection
            }
          />
        </>
      )}
    </section>
  )
}

export default Destinations
